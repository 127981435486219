import { useState } from 'react';
import {
  FormLabel, Input, Textarea, Button, Box, 
  Image, Heading, Text, Spinner, useToast, 
  VStack, HStack, Container, Center
} from '@chakra-ui/react';

function App() {
  const [storeDetails, setStoreDetails] = useState('');
  const [productImage, setProductImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const toast = useToast();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProductImage(file);
    setPreviewImage(URL.createObjectURL(file)); 
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('storeDetails', storeDetails);
    formData.append('image', productImage);

    try {
      // const response = await fetch('http://localhost:3001/api/generate', {
      const response = await fetch('https://playground-backend-8jk8.onrender.com/api/generate', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setGeneratedData(data);
        setEditedImage(data.editedImage);
      } else {
        toast({
          title: 'Error',
          description: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error); 
      toast({
        title: 'Error',
        description: 'There was an error processing your request.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Center>
      <Container maxW="container.lg" p={8}>
        <Heading mb={4}>Lyric Generator</Heading>

        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <Box>
              <FormLabel htmlFor="storeDetails">Store Details:</FormLabel>
              <Textarea 
                id="storeDetails" 
                value={storeDetails} 
                onChange={(e) => setStoreDetails(e.target.value)} 
                placeholder="Enter your store's story and brand personality" 
                height={200} 
              />
            </Box>
            <Box>
              <FormLabel htmlFor="image">Product Image:</FormLabel>
              <Input 
                type="file" 
                id="image"
                accept="image/*" 
                onChange={handleImageChange} 
              />
              {previewImage && (
                <Image src={previewImage} alt="Product Preview" maxH={200} mt={2} />
              )}
            </Box>
            <Button type="submit" colorScheme="teal" isLoading={isLoading} loadingText="Generating, will take 20s...">
              Generate Description
            </Button>
          </VStack>
        </form>

        {generatedData && editedImage && (
          <Box mt={8} p={4} borderWidth="1px" borderRadius="md">
            <Heading size="md" mb={2}>Generated Description:</Heading>
            <Image src={editedImage} alt="Edited Product" maxH={200} mb={2} /> {/* Display the image */}
        <Text fontWeight="bold">{generatedData.title}</Text>
        <Text>{generatedData.description}</Text>
        <Text>
              Suggested Price Range: ${generatedData.suggested_price_low} - ${generatedData.suggested_price_high}
            </Text>
          </Box>
        )}
      </Container>
    </Center>
  );
}

export default App;
